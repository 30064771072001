import { jwtDecode } from 'jwt-decode';

export const getAuthToken = (): string | null => localStorage.getItem('authToken');

export const getRefreshToken = (): string | null => localStorage.getItem('refreshToken');

export const getUser = (): string | null => localStorage.getItem('user');

export const setAuthToken = (token: string): void => localStorage.setItem('authToken', token);

export const setRefreshToken = (token: string): void => localStorage.setItem('refreshToken', token);

export const setUser = (user: string): void => localStorage.setItem('user', user);

export const removeTokens = (): void => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');
};

export const checkTokenExpiry = (token: string | null): boolean => {
  if (!token) return false;
  const decodedToken: any = jwtDecode(token);
  return decodedToken.exp * 1000 > Date.now();
};

export const getTokenExpiry = (token: string | null): number | null => {
  if (!token) return null;
  const decodedToken: any = jwtDecode(token);
  return decodedToken.exp ? decodedToken.exp * 1000 : null;
};

export const checkTokenValidity = (token: string | null): boolean => {
  if (!token) return false;
  const decodedToken: any = jwtDecode(token);
  const currentTime = Math.floor(Date.now() / 1000);
  return (currentTime >= Number(decodedToken.exp) - 60);
};
