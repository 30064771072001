import React, { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/public/Login';
import Home from './components/protected/Home';
import { AuthContext } from './context/AuthContext';

const App: React.FC = () => {
  const authContext = useContext(AuthContext);

  if (authContext?.loading) {
    return <p>Loading...</p>;
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/home" element={authContext?.isAuthenticated ? <Home /> : <Navigate to="/login" />} />
      <Route path="*" element={<Navigate to={authContext?.isAuthenticated ? '/home' : '/login'} />} />
    </Routes>
  );
};

export default App;
