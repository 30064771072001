import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { checkTokenValidity } from '../services/TokenService';
import { refreshAuthToken, logoutUser } from '../services/AuthService';

interface AuthContextProps {
  isAuthenticated: boolean;
  loading: boolean;
  setIsAuthenticated: (auth: boolean) => void;
  handleLogout: () => void;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const refreshToken = localStorage.getItem('refreshToken');

    const validateAuth = async () => {
      if (authToken && checkTokenValidity(authToken)) {
        setIsAuthenticated(true);
      } else if (refreshToken) {
        const newToken = await refreshAuthToken();
        if (newToken) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    };

    validateAuth();
  }, []);

  const handleLogout = () => {
    logoutUser();
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, setIsAuthenticated, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
