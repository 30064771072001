import React, { useEffect, useState, useContext } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { login } from '../../services/AuthService';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext)!;
  const [loading, setLoading] = useState(false);

  const handleLoginSuccess = async (tokenResponse: any) => {
    setLoading(true);
    try {
      await login(tokenResponse.credential);
      setIsAuthenticated(true);
      setLoading(false);
      navigate('/home');
    } catch (error) {
      console.error('Error during login:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home');
    }
  }, [isAuthenticated, navigate]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <div className="login-page">
        <h1>Login with Google</h1>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <GoogleLogin onSuccess={handleLoginSuccess} onError={() => console.error('Login failed')} />
        )}
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
