import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleProtectedRequest } from '../../services/AuthService';
import { AuthContext } from '../../context/AuthContext';

const Home: React.FC = () => {
  const [protectedData, setProtectedData] = useState<string | null>(null);
  const { handleLogout } = useContext(AuthContext)!;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/login');
    } else {
      setLoading(false);
    }
  }, [navigate]);

  const fetchProtectedData = async () => {
    const data = await handleProtectedRequest();
    if (data) {
      setProtectedData(data.message);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  let user: { id?: string, email?: string, name?: string } | null = null;
  const storedUser = localStorage.getItem('user');
  if (storedUser) {
    user = JSON.parse(storedUser);
  }

  return (
    <div className="home-page">
      <h1>{ user?.name }, welcome to the Protected Home Page!</h1>
      <button onClick={fetchProtectedData}>Access Protected Data</button>
      {protectedData && <p>Protected Data: {protectedData}</p>}
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Home;
